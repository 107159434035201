import { useCallback, useMemo } from 'react';
import { createOrderRequest } from 'lib/services/payments';
import useWindowDataLayer from '@/hooks/useWindowDataLayer';
import useCookies from '@/hooks/useCookies';
import { useAppState } from '@/hooks/useAppState';

const useOrderCreate = () => {
  const { push: dataLayerPush } = useWindowDataLayer();
  const [{ _ga }] = useCookies();
  const [{ device, publishedUrl, pageId, type }] = useAppState();

  const createOrder = useCallback(
    async (userCC: string, authorizationToken: string) => {
      const orderDetails = await createOrderRequest(userCC, authorizationToken, {
        device,
        pageId,
        gaId: _ga,
        type,
        publishedUrl,
      });
      const price = orderDetails.orderAmount / 100;
      dataLayerPush({
        event: 'purchase',
        ecommerce: {
          transaction_id: orderDetails.orderId,
          currency: orderDetails.purchaseCurrency,
          value: price,
          items: [{ price, item_name: orderDetails.purchaseTag, item_id: orderDetails.purchaseTag }],
        },
      });
    },
    [device, publishedUrl, pageId, type, _ga, dataLayerPush],
  );

  return useMemo(() => ({ createOrder }), [createOrder]);
};

export default useOrderCreate;
